import { useStaticQuery, graphql } from "gatsby"

export const useEmpreses = () => {
    
    const empreses= useStaticQuery(  graphql`
       query  {
        allEmpresa {
          nodes {
            allGranjes {
                marca
                nomMarca
                usuari
                geoloc {
                    x
                    y
                }
            }
            allNaus {
                nomMarca
                marca
                nau
                nomnau
            }
            idEmpresa
            granjesMares {
                marca
                nomMarca
                idNau
                usuari
            }
            granjesDestete {
                marca
                nomMarca
                usuari
                naus {
                  idNau
                  nomNau
                }
            }
            granjesEngreix {
                marca
                nomMarca
                usuari
                naus {
                  idNau
                  nomNau
                }
              }
            transports {
              id_transportista
              nom_transportista
              xofers {
                id_xofer
                nom_xofer
              }
            }
            destiEscorxador {
                id
                NOM
            }
            families {
                id_familia
                descripcio               
                subfamilies {
                  id_subfamilia
                  dessubfamilia
                }
            }
          }
        }

      }
   ` )
   return empreses;
 }
 

 export const especific = (granjes,user) => {
      console.log("--és especific?"+user.nivell.especific)
      if (user.nivell.especific!=1) return granjes;
      var finalGranjes = [];
      for (var obj in granjes){
         // console.log("granjes= "+granjes[obj].usuari+","+granjes[obj].marca)
          if (granjes[obj].usuari==user.id) {
          console.log("buscant usuari especific i trobat! "+granjes[obj].marca)
            finalGranjes.push(granjes[obj]);
          }
      }
      //console.log("buscant usuari especific.... total="+finalGranjes.length)
     // if (finalGranjes.length==0) return granjes
      return finalGranjes
  }