import React, { Component } from "react";
import classNames from "classnames"

class Autocomplete extends Component {
  constructor(props) {
    //console.log("suggestions = "+JSON.stringify(suggestions))
    const { suggestions } = props; 
    super(props);
    this.state = {
      globalList:suggestions,
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      fakeLabel: "",
      ignoreBlur: true,
      userInput: "",
    };
   
  }
 
   onChange = e => {
    const { suggestions } = this.props;
    //console.log("suggestions = "+JSON.stringify(this.props))
    this.setState({
      globalList:suggestions
    })
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      fakeLabel: e.currentTarget.innerText,
      userInput: e.currentTarget.value
    });
  
  };
  onClick = (e) => {
    e.preventDefault();
  
    const { globalList } = this.state;
    //console.log("onclick!"+e.currentTarget.innerText)
    //console.log("globallist="+JSON.stringify(globalList))
    
    //this.props.callbackValue(e.currentTarget.innerText)
    let index=this.findIndex(globalList,e.currentTarget.innerText)
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      fakeLabel: e.currentTarget.innerText,
      userInput: e.currentTarget.getAttribute('datakey')
    });
    console.log("index="+index)
    this.props.callbackValue(e.currentTarget.innerText,index)

  };
  findIndex(list,value){
    for (var i=0;i<list.length;i++){
      //console.log("value="+value+","+list[i].label)
      if (value===list[i].label) {
        //console.log("found"+i)
        return i;
      }
    }
    return null
  }
  onClickInput = e => {
    const { suggestions } = this.props;
    const filteredSuggestions = suggestions;
    this.setState({
      globalList:suggestions,
      userInput: "",
      filteredSuggestions,
      showSuggestions: true,
      fakeLabel: ""
    });
  };
  onKeyDown = e => {
    const { suggestions } = this.props;
    const { activeSuggestion, filteredSuggestions, showSuggestions, userInput } = this.state;
    const globalList = suggestions;
    if (e.keyCode === 13) {
      e.preventDefault();
      if (showSuggestions){
        let index=this.findIndex(globalList,filteredSuggestions[activeSuggestion].label);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion].label
        });
        this.props.callbackValue(filteredSuggestions[activeSuggestion].label,index)
      } else {
        const { suggestions } = this.props;
        const filteredSuggestions = suggestions;
        this.setState({
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          userInput: filteredSuggestions[activeSuggestion].label
        });
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ 
        activeSuggestion: activeSuggestion - 1,
        userInput: filteredSuggestions[activeSuggestion - 1].label 
      });
      let index=this.findIndex(globalList,filteredSuggestions[activeSuggestion - 1].label);
      this.props.callbackValue(filteredSuggestions[activeSuggestion - 1].label,index)
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      } else if ( userInput === ""){
        const { suggestions } = this.props;
        const filteredSuggestions = suggestions;
        this.setState({
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          userInput: filteredSuggestions[activeSuggestion].label
        });
        let index=this.findIndex(globalList,filteredSuggestions[activeSuggestion].label);
        this.props.callbackValue(filteredSuggestions[activeSuggestion].label,index)
      }else {
        this.setState({ 
          activeSuggestion: activeSuggestion + 1,
          userInput: filteredSuggestions[activeSuggestion + 1].label 
        });
        let index=this.findIndex(globalList,filteredSuggestions[activeSuggestion + 1].label);
      this.props.callbackValue(filteredSuggestions[activeSuggestion + 1].label,index)
      }
    }
  };
  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onClickInput,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        fakeLabel,
        userInput
      }
    } = this;

    let suggestionsListComponent;
    if (showSuggestions) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="field-wrapper-autocomplete-suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion.value}>
                  <button datakey={suggestion.value} onMouseDown={onClick}>{suggestion.label}</button>
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-field-wrapper-autocomplete-suggestions">
            <em>Sense resultats</em>
          </div>
        );
      }
    }
    return (
      <div className="field-wrapper field-wrapper-autocomplete">
        <input className={classNames(userInput && 'dirty', fakeLabel && 'labeled')} autoComplete="off" type="text"
            name={this.props.inputName} onChange={onChange} onClick={onClickInput} onKeyDown={onKeyDown} value={fakeLabel?fakeLabel:userInput} />
        <label htmlFor={this.props.inputName}>{this.props.inputLabel}</label>
        {/* fakeLabel && (
          <div className="fakelabel">{fakeLabel}</div>
        /)*/}
        {suggestionsListComponent}
      </div>
    );
  }
}

export default Autocomplete;
