import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import Header from "../../components/header"
import HeaderMenu from "../../components/header-menu"
import Footer from "../../components/footer"
import { getUser} from "../../services/auth"
import {useEmpreses} from "../../helpers/useEmpreses"
import { setItem} from "../../services/session"
import Autocomplete from "../../components/autocomplete"

const GranjaVeterinari = ({ location }) => {
 
  const { state = {} } = location
  const  typeGranja  = state.typeGranja
  
  var empreses=useEmpreses()
  const [granjesMares, setGranjesMares] = useState([]);
  const [granjesDestete, setGranjesDestete] = useState([]);
  const [granjesEngreix, setGranjesEngreix] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [granjaSelected,setGranjaSelected] =useState("");
  const [granjaStringSelected, setGranjaStringSelected] = useState("");
  const [labelInput, setLabelInput] = useState("")
  const [arrayGranjesAutocomplete, setArrayGranjesAutocomplete] = useState([])
  
  function onClicking(event){
    event.preventDefault();
    setItem("userGranjaTecGranja",{
      "granjaSelected": granjaSelected,
      "granjaStringSelected" : granjaStringSelected
    });
    navigate("/veterinaris/menuVisites")
  } 
   
  const handleGranjaChange= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, '');
    setGranjaSelected(code)
    setGranjaStringSelected(code+"-"+granjaText)
  }

  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa===idEmpresa) {
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  useEffect( () => {
    var empresa=""
    let _array=[];
    if (!getUser() || getUser().nivell.veterinari!==1) {
        navigate("../../mainMenu") 
    }

    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
    setGranjesMares(empresa.granjesMares)
    setGranjesDestete(empresa.granjesDestete)
    setGranjesEngreix(empresa.granjesEngreix)
    let objectGranjes;
    if (typeGranja === "Mares") {
       objectGranjes = Object.entries(empresa.granjesMares);
       setLabelInput("Mares")
    }
    if (typeGranja === "Destete") {
       objectGranjes = Object.entries(empresa.granjesDestete);
       setLabelInput("Destete")
    } 
    if (typeGranja === "Engreix") {
      objectGranjes = Object.entries(empresa.granjesEngreix);
       setLabelInput("Engreix")
    }
  
    objectGranjes.forEach(([key, value]) => {
      var item = {};
      if (value.marca){
        item.value = value.marca;
        item.label = value.marca + "-" + value.nomMarca;
        _array.push(item);
      }
      setArrayGranjesAutocomplete(_array)
    });
   
    },
  []);

 


  
  return (
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">{typeGranja}</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
                <form className="steps">
                <div className="steps-panel">
                  <Autocomplete suggestions={arrayGranjesAutocomplete} inputLabel={labelInput} inputName="desti-destete" callbackValue={handleGranjaChange}/>   
                                   
                  <div className="steps-nav">
                    <button onClick={onClicking} className='steps-nav-btn steps-nav-btn-next'>
                      <span>Next</span>
                    </button>
                  </div>
                </div>


                </form>
              </section>
              { isLoading && (
              <div className="overlay-loading"></div>
              )}
            </div>
         
          <Footer/>
        </div>

)}

export default GranjaVeterinari
